import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, { FC, ReactNode } from 'react'

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-of-type)': {
      marginBottom: spacing(4),
    },
  },
  multiline: {
    alignItems: 'flex-start',
  },
  icon: {
    marginRight: spacing(2.5),
    fontSize: '2.25rem',
    lineHeight: 0,
    color: palette.primary.main,
    [breakpoints.down('sm')]: {
      marginRight: spacing(1.5),
      fontSize: '1.5rem',
    },
    '& > svg': {
      fill: 'currentColor',
      height: '1em',
      width: '1em',
    },
  },
  text: {
    margin: 0,
    fontSize: '1rem',
    letterSpacing: '0.03125em',
    whiteSpace: 'pre',
    color: palette.text.primary,
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.033333em',
    },
  },
}))

type InfoItemProps = {
  icon: ReactNode
  multiline?: boolean
}

export const InfoItem: FC<InfoItemProps> = ({
  children,
  icon,
  multiline,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div
      className={clsx(classes.root, { [classes.multiline]: multiline })}
      {...props}
    >
      <div className={classes.icon}>{icon}</div>
      <p className={classes.text}>{children}</p>
    </div>
  )
}
