import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { ButtonLink } from '@/components/common/ButtonLink'
import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    title: {
      marginBottom: spacing(6),
      [breakpoints.down('md')]: {
        marginBottom: spacing(4),
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      [breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
    description: {
      flex: 1,
      order: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      maxWidth: 520,
      marginRight: spacing(18),
      [breakpoints.down('md')]: {
        order: 2,
        alignItems: 'center',
        marginRight: 0,
        padding: spacing(0, 4),
      },
    },
    motto: {
      marginBottom: spacing(2),
      fontSize: '1.875rem',
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.066667em',
      color: palette.text.primary,
      textAlign: 'left',
      [breakpoints.down('md')]: {
        marginBottom: spacing(1),
        fontSize: '1.25rem',
        letterSpacing: '0.019286em',
        textAlign: 'center',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    verse: {
      marginBottom: spacing(6),
      fontSize: '1.125rem',
      lineHeight: 1.556,
      letterSpacing: '0.027778em',
      color: palette.text.primary,
      textAlign: 'left',
      [breakpoints.down('md')]: {
        marginBottom: spacing(4),
        fontSize: '1rem',
        lineHeight: 1.5,
        textAlign: 'center',
      },
      [breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    reference: {
      display: 'block',
    },
    caption: {
      fontSize: '1rem',
      lineHeight: 1.5,
      [breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    video: {
      flex: 1,
      order: 2,
      [breakpoints.down('md')]: {
        order: 1,
      },
    },
    embed: {
      display: 'block',
      width: 512,
      height: 288,
      border: 'none',
      margin: '0 auto',
      [breakpoints.down('md')]: {
        width: 400,
        height: 225,
        marginBottom: spacing(5),
      },
      [breakpoints.down('sm')]: {
        width: 320,
        height: 180,
        marginBottom: spacing(3),
      },
    },
  })
)

export const MissionSection: FC = () => {
  const classes = useStyles()

  return (
    <PageContainer background="default" shadow>
      <SectionTitle className={classes.title}>Our Mission</SectionTitle>
      <div className={classes.row}>
        <div className={classes.description}>
          <div className={classes.motto}>
            <q>Helping people find and follow Jesus</q>
          </div>
          <div className={classes.verse}>
            <em>
              Therefore go and make disciples of all nations, baptizing them in
              the name of the Father and of the Son and of the Holy Spirit, and
              teaching them to obey everything I have commanded you. And surely
              I am with you always, to the very end of the age.
            </em>
            <strong className={classes.reference}>Matthew 28:19-20</strong>
          </div>
          <ButtonLink to="/about-us/mission-identity">Learn More</ButtonLink>
        </div>
        <div className={classes.video}>
          <iframe
            src="https://www.youtube-nocookie.com/embed/BvwCuYV-1EU?cc_load_policy=1&cc_lang_pref=en"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Welcome to IBC Berlin"
            className={classes.embed}
          />
        </div>
      </div>
    </PageContainer>
  )
}
