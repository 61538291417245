import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    subsection: {
      flex: 'none',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      maxWidth: 420,
    },
    textLeft: {
      textAlign: 'left',
      width: '100%',
      maxWidth: '100%',
    },
    spacingTop: {
      marginTop: spacing(8),
    },
    title: {
      marginBottom: spacing(8),
      [breakpoints.down('sm')]: {
        marginBottom: spacing(4),
      },
    },
    subtitle: {
      marginBottom: spacing(1),
      fontSize: '3rem',
      fontWeight: typography.fontWeightBold,
      letterSpacing: '0.007291em',
      color: palette.text.primary,
      [breakpoints.down('sm')]: {
        marginBottom: spacing(2),
        fontSize: '1.5rem',
      },
    },
    description: {
      fontSize: '1.5rem',
      letterSpacing: '0.052083em',
      [breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
  })
)

export const ScheduleSection: FC = () => {
  const classes = useStyles()
  const specialServiceDate = new Date('2024-06-16T11:00:00')
  const divClasses = specialServiceDate > new Date() ? `${classes.subtitle}  ${classes.spacingTop}` : `${classes.subsection}`

  return (
    <PageContainer shadow className={classes.root}>
      {specialServiceDate > new Date() && (
        <div className={`${classes.subsection}  ${classes.textLeft}`}>
          <div className={`${classes.subtitle}`}>Sunday, June 16 at 11:00</div>
          <div className={`${classes.subsection}`}>
            Special bilingual service with our host church.
            <br />
            Rothenburgstraße 12A
            <br />
            12165 Berlin
          </div>
        </div>
      )}
      <div className={`${classes.subsection}`}>
        <div className={divClasses}>Sundays at 13:00</div>
        <div className={classes.description}>
          Rothenburgstraße 12A
          <br />
          12165 Berlin
        </div>
      </div>
    </PageContainer>
  )
}
