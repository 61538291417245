import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import ConnectIcon from '@/assets/svg/connect.svg'
import GoIcon from '@/assets/svg/go.svg'
import GrowIcon from '@/assets/svg/grow.svg'
import ServeIcon from '@/assets/svg/serve.svg'
import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'

import { StepCircle, StepCircleProps } from './StepCircle'

const steps: StepCircleProps[] = [
  { label: 'Connect', icon: <ConnectIcon />, color: 'pink' },
  { label: 'Grow', icon: <GrowIcon />, color: 'green' },
  { label: 'Serve', icon: <ServeIcon />, color: 'blue' },
  { label: 'Go', icon: <GoIcon />, color: 'purple' },
]

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: spacing(8),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(5),
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: spacing(-5, -5, 5),

    '& > *': {
      margin: spacing(2.5),
    },
    [breakpoints.down('md')]: {
      maxWidth: 660,
      flexWrap: 'wrap',
    },
    [breakpoints.down('sm')]: {
      maxWidth: 240,
      margin: spacing(-1.5, -1.5, 3.5),

      '& > *': {
        margin: spacing(1.5),
      },
    },
  },
}))

export const NextStepsSection: FC = () => {
  const classes = useStyles()

  return (
    <PageContainer shadow className={classes.root}>
      <SectionTitle className={classes.title}>
        Our Disciple Life Steps
      </SectionTitle>
      <div className={classes.row}>
        {steps.map((step) => (
          <StepCircle key={step.label} {...step} />
        ))}
      </div>
    </PageContainer>
  )
}
