import {
  Checkbox,
  FormControlLabel,
  Input,
  InputLabel,
  makeStyles,
  Paper,
} from '@material-ui/core'
import React, { FC } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FormButton } from '@/components/common/FormButton'
import { useFormService } from '@/hooks/useFormService'

const ids = {
  form: 'contact-form',
  name: 'contact-form-name',
  email: 'contact-form-email',
  message: 'contact-form-message',
  consent: 'contact-form-consent',
}

const useStyles = makeStyles(({ breakpoints, palette, spacing }) => ({
  root: {
    flex: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: 460,
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
    borderRadius: 6,
    padding: spacing(5),
    [breakpoints.down('sm')]: {
      maxWidth: 360,
      width: '100%',
      padding: spacing(4, 3, 3),
    },
  },
  description: {
    marginBottom: spacing(3),
    fontSize: '1rem',
    letterSpacing: '0.03125em',
    color: palette.text.primary,
    [breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      letterSpacing: '0.009286em',
    },
  },
  fields: {
    alignSelf: 'stretch',
  },
  field: {
    marginBottom: spacing(6),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(4),
    },
  },
  agreement: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: spacing(-1.5),
    marginBottom: spacing(6),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(4),
    },
  },
  checkbox: {
    '& svg': {
      height: 30,
      width: 30,
    },
  },
  checkboxLabel: {
    fontSize: '0.875rem',
    letterSpacing: '0.009286em',
    color: '#393939',
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.015em',
    },
  },
  link: {
    color: 'inherit',
  },
}))

type FormInputs = {
  name: string
  email: string
  message: string
  consent: boolean
}

type ContactFormProps = {
  encryptedEmail: string
}

export const ContactForm: FC<ContactFormProps> = ({ encryptedEmail }) => {
  const classes = useStyles()
  const { pending, submit } = useFormService()
  const { control, handleSubmit, register, reset } = useForm<FormInputs>({
    defaultValues: {
      name: '',
      email: '',
      message: '',
      consent: false,
    },
  })
  const { ref: nameRef, ...nameRest } = register('name', { required: true })
  const { ref: emailRef, ...emailRest } = register('email', { required: true })
  const { ref: messageRef, ...messageRest } = register('message', {
    required: true,
    minLength: 10,
  })

  const onSubmit = async (data: FormInputs) => {
    const payload = {
      visitor_name: data.name,
      visitor_email: data.email,
      message: data.message,
      recipient_name: 'Contact IBCB',
      recipient_email: encryptedEmail,
      email_type: 'homepage-contact',
    }

    await submit(payload, reset)
  }

  return (
    <>
      <Paper
        component="form"
        id={ids.form}
        className={classes.root}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className={classes.description}>
          Complete this to connect with us for a question or to set up an
          appointment.
        </div>
        <div className={classes.fields}>
          <div className={classes.field}>
            <InputLabel htmlFor={ids.name}>Name</InputLabel>
            <Input
              id={ids.name}
              type="text"
              placeholder="Your name"
              required
              inputRef={nameRef}
              {...nameRest}
            />
          </div>
          <div className={classes.field}>
            <InputLabel htmlFor={ids.email}>Email</InputLabel>
            <Input
              id={ids.email}
              type="email"
              placeholder="valid-email@address.com"
              required
              inputRef={emailRef}
              {...emailRest}
            />
          </div>
          <div className={classes.field}>
            <InputLabel htmlFor={ids.message}>Message</InputLabel>
            <Input
              id={ids.message}
              placeholder="How can we help you?"
              inputProps={{
                minLength: 10,
              }}
              rows={3}
              multiline
              required
              inputRef={messageRef}
              {...messageRest}
            />
          </div>
        </div>
        <FormControlLabel
          classes={{
            root: classes.agreement,
            label: classes.checkboxLabel,
          }}
          id={ids.consent}
          control={
            <Controller
              name="consent"
              control={control}
              render={({ field }) => (
                <Checkbox
                  color="primary"
                  {...field}
                  className={classes.checkbox}
                  checked={field.value}
                  onChange={(event) => field.onChange(event.target.checked)}
                  required
                />
              )}
            />
          }
          label={
            <span>
              I have read and agreed to the&nbsp;
              <a
                href="/data-protection"
                target="_blank"
                className={classes.link}
              >
                Data Protection statement
              </a>
              .
            </span>
          }
          htmlFor={ids.consent}
        />
        <FormButton pending={pending}>Submit</FormButton>
      </Paper>
    </>
  )
}
