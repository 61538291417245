import { makeStyles } from '@material-ui/core'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'

const useStyles = makeStyles(
  ({ breakpoints, palette, spacing, typography }) => ({
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: spacing(10, 22, 20),
      color: palette.primary.contrastText,
      [breakpoints.down('sm')]: {
        padding: spacing(5, 2.5, 8),
      },
    },
    image: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    title: {
      zIndex: 100,
      marginBottom: spacing(5),
      fontSize: '3rem',
      fontWeight: typography.fontWeightMedium,
      letterSpacing: '0.010417em',
      lineHeight: 1.2,
      textAlign: 'center',
      color: 'inherit',
      [breakpoints.down('sm')]: {
        fontSize: '1rem',
        letterSpacing: '0.009375em',
      },
    },
    subtitle: {
      zIndex: 100,
      marginBottom: spacing(25),
      fontSize: '1.25rem',
      letterSpacing: '0.0625em',
      lineHeight: 1.2,
      color: 'inherit',
      textShadow: '1px 1px rgba(51, 51, 51, 1)',
      whiteSpace: 'pre',
      [breakpoints.down('sm')]: {
        marginBottom: spacing(12),
        fontSize: '0.75rem',
        letterSpacing: '0.033333em',
      },
    },
  })
)

type HeroProps = {
  image: IGatsbyImageData
}

export const Hero: FC<HeroProps> = ({ image }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <GatsbyImage
        image={image}
        alt=""
        className={classes.image}
        style={{ position: 'absolute' }}
      />
      <div className={classes.title}>International Baptist Church Berlin</div>
      <div className={classes.subtitle}>
        Multi-ethnic | English-speaking | Evangelical
      </div>
    </div>
  )
}
