import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React, {
  cloneElement,
  ComponentPropsWithoutRef,
  FC,
  ReactElement,
} from 'react'

type Color = 'pink' | 'green' | 'blue' | 'purple'

const colors: Record<Color, string> = {
  pink: 'rgba(208, 2, 27, 0.6)',
  green: 'rgba(95, 151, 31, 0.8)',
  blue: 'rgba(28, 99, 151, 0.8)',
  purple: 'rgba(130, 59, 223, 0.8)',
}

const useStyles = makeStyles(({ breakpoints, spacing, typography }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: 250,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    [breakpoints.down('sm')]: {
      height: 80,
      width: 80,
    },
  },
  icon: {
    height: 100,
    width: 100,
    [breakpoints.down('sm')]: {
      height: 32,
      width: 32,
    },
  },
  label: {
    marginTop: spacing(1),
    fontSize: '1.375rem',
    letterSpacing: '0.074545em',
    fontWeight: typography.fontWeightBold,
    textTransform: 'uppercase',
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.041667em',
    },
  },
  pink: {
    color: colors.pink,
  },
  green: {
    color: colors.green,
  },
  blue: {
    color: colors.blue,
  },
  purple: {
    color: colors.purple,
  },
}))

export type StepCircleProps = ComponentPropsWithoutRef<'div'> & {
  icon: ReactElement
  label: string
  color: Color
}

export const StepCircle: FC<StepCircleProps> = ({
  className,
  icon,
  label,
  color,
  ...rest
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, classes[color], className)} {...rest}>
      <div className={classes.circle}>
        {cloneElement(icon, { className: classes.icon })}
      </div>
      <div className={classes.label}>{label}</div>
    </div>
  )
}
