import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import { PageContainer } from '@/components/common/PageContainer'
import { SectionTitle } from '@/components/common/SectionTitle'

import { ContactForm } from './ContactForm'
import { ContactInfo } from './ContactInfo'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  title: {
    marginBottom: spacing(10),
    [breakpoints.down('sm')]: {
      marginBottom: spacing(5),
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      margin: spacing(0, 2),
    },
  },
}))

type ContactSectionProps = {
  encryptedEmail: string
}

export const ContactSection: FC<ContactSectionProps> = ({ encryptedEmail }) => {
  const classes = useStyles()

  return (
    <PageContainer background="default" id="contact-us">
      <SectionTitle className={classes.title}>Contact Us</SectionTitle>
      <div className={classes.row}>
        <ContactInfo />
        <ContactForm encryptedEmail={encryptedEmail} />
      </div>
    </PageContainer>
  )
}
