import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import { ContactSection } from '@/components/home/ContactSection'
import { Hero } from '@/components/home/Hero'
import { MissionSection } from '@/components/home/MissionSection'
import { NextStepsSection } from '@/components/home/NextStepsSection'
import { ScheduleSection } from '@/components/home/ScheduleSection'
import { SEO } from '@/components/SEO'

const query = graphql`
  query {
    coverImage: strapiCoverImages(slug: { eq: "home" }) {
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    home: strapiHome {
      contact_id
    }
  }
`

const HomePage: FC = () => {
  const data = useStaticQuery(query)
  const image = getImage(data.coverImage.cover.localFile)

  return (
    <>
      <SEO title="Home" />
      <Hero image={image} />
      <ScheduleSection />
      <MissionSection />
      <NextStepsSection />
      <ContactSection encryptedEmail={data.home.contact_id} />
    </>
  )
}

export default HomePage
