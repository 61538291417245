import { makeStyles } from '@material-ui/core'
import React, { FC } from 'react'

import BusinessIcon from '@/assets/svg/business.svg'
import ChildrenIcon from '@/assets/svg/children.svg'
import ParkingIcon from '@/assets/svg/parking.svg'
import PhoneIcon from '@/assets/svg/phone.svg'
import PlaceIcon from '@/assets/svg/place.svg'
import TransportIcon from '@/assets/svg/transport.svg'
import { InfoItem } from '@/components/home/InfoItem'

const useStyles = makeStyles(({ breakpoints, spacing }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: spacing(15),
    marginRight: spacing(4),
    [breakpoints.down('md')]: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: spacing(3),
    },
  },
  list: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  listItem: {
    fontSize: '1rem',
    letterSpacing: '0.03125em',
    lineHeight: 2,
    whiteSpace: 'pre',
    tabSize: 8,
    [breakpoints.down('sm')]: {
      fontSize: '0.75rem',
      letterSpacing: '0.033333em',
      lineHeight: 1.667,
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
}))

const info = {
  contactNumber: '+49 (0)30 6027 2548',
  mapLink: 'https://goo.gl/maps/bZtvtnfzQhfDxwXq7',
  address: 'Rothenburgstraße 12A\n12165 Berlin\nGermany',
}

export const ContactInfo: FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <InfoItem icon={<PhoneIcon />}>
        <a href={`tel:${info.contactNumber}`} className={classes.link}>
          {info.contactNumber}
        </a>
      </InfoItem>
      <InfoItem icon={<PlaceIcon />} multiline>
        <a href={info.mapLink} className={classes.link}>
          {info.address}
        </a>
      </InfoItem>
      <InfoItem icon={<BusinessIcon />}>
        For an appointment, please fill out the form <br />
        and we will contact you soon.
      </InfoItem>
      <InfoItem icon={<ParkingIcon />}>Limited parking available</InfoItem>
      <InfoItem icon={<TransportIcon />}>
        S1, U9, Buses (<strong>S & U - Rathaus Steglitz</strong>)
      </InfoItem>
      <InfoItem icon={<ChildrenIcon />}>Kid-friendly</InfoItem>
    </div>
  )
}
